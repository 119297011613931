.main_div {
  width: 100%;
  height: 100vh;
  position: relative;
}
.header {
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 25px;
}
.heading {
  color: #000;
font-family: IBM Plex Serif;
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
border: none;
width: 300px;
margin-top: 12px;
}
.header button {
  padding: 3px 15px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  background: var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%));
  height: 26px;
}
.summary_edit_section {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-top:-22px;
}
.edit_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.highlighter {
  display: flex;
  width: 28px;
  padding: 5px 0px 5px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #cecece;
  background: var(--white, #fff);
  cursor: pointer;
}
.highlighter img {
  width: 16px;
  height: 15px;
}
.text_view_section{
  display: flex;
  flex-direction: row;
  padding: 3px 11px;
  justify-content: center;
  align-items: baseline;
  gap: 8px;
  margin: 0 10px;
  border-radius: 5px;
  border: 1px solid #cecece;
  background: var(--pdfbg, #fffcfc);
  font-family: "IBM Plex sans", sans;
  z-index: 999999;
}
.text_edit_section {
  display: flex;
  flex-direction: row;
  padding: 3px 11px;
  justify-content: center;
  align-items: baseline;
  gap: 8px;
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid #cecece;
  background: var(--pdfbg, #fffcfc);
  font-family: "IBM Plex sans", sans;
  z-index: 999999;
}
/* .pdf_container{
  user-select: text; /* Allow text selection */
/* } */ 
.small_A {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}
.capital_A {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.summary_section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 94%;
  font-family: "IBM Plex sans", sans;
  border-radius: 5px;
  font-style: normal;
  line-height: 24px;
  padding: 3px 11px 11px 11px;
  margin-top: 10px;
  z-index: 3;

}

.summary_section p {
  margin: 0px;
  font-size: 14px;


}
.summary_p{
  color: #000;
font-family: IBM Plex Sans;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
height: 140px;
overflow: scroll;
}
.summary_p::-webkit-scrollbar {
  width: -1em !important;
}

.summary_p::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.summary_p::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}
.viewSet{
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  width: max-content;
  padding: 5px 0px 5px 0px;
}

.hide_parent{
  display: flex;
  justify-content: start;
  align-items: start;
  margin: 10px 0;
}
.hide_parent button {
  width: 55px;
  height: 23px;
  padding: 3px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 12px;
  background: #1d1d1d;
  border: none;
  margin: 0;
}
.footer {
  position: relative;
  bottom: 0px;
  width: 100%;
}
.bottomMenu {
  position: absolute;
  z-index: 999;
  bottom: 34%;
  width: 100%;
  
}
.submenu{
  display: flex;
  width: 100%;
}
.footer_icons {
  display: flex;
  justify-content: start;
  flex-direction: column;
  width:46px;
  height: 70px;
  height: 45px;
  margin-left: 10px;
}
.footer_icons p {
  color: #000;
text-align: center;
font-family: IBM Plex Sans;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
margin: 0;
}
.footer_icons img {
 margin: 3px;

}
.footer_icons div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #cecece;
  background: var(--white, #fff);
}
.teach_icon {
  border-radius: 25px 25px 0px 0px;
  padding: 8px 4px 8px 4px;
}
.draw_icon {
  border-radius: 0px 0px 25px 25px;
  padding: 8px 4px 8px 4px;
}
.tracker_icon {
  border-radius: 25px;
  margin-top: 7px;
  padding: 8px 16px;
}
.character_img {
  width: 100px;
  position: absolute;
  bottom: -2rem;
  right: 0;

}
.distortion{
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  bottom:0rem;
  right: 0;
  height: 80px;
  width: 100%;
  z-index: 1;
  backdrop-filter: blur(100px);

}
.second_section {
  padding: 10px;
  position: relative;
  overflow: scroll;
  height: 100vh;
  padding: 30px;
  background: var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))
}

.second_section_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  font-family: "IBM Plex Sans", sans;
  font-size: 23px;
  line-height: 1.7;
  padding: 14px 14px;
  height: 100vh;
  background: var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))
}
.second_section p {
  margin-top: 18px;
}
.fade_in_paragraph {
  opacity: 1;
  animation: fadeIn 10s ease-in-out forwards;
}
.summary_heading {
  margin-top: 5px;
  font-weight: 600;
}

.fade_in_line {
  opacity: 1;
}
.gif_container{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  }
  .gif_container_gif{
    margin-top: 40px;
    width: 25%;
  }
  .question{
    color: #6C6C6C;
  font-family: IBM Plex Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px; /* 192.857% */
  }
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.menu_item_custom {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Change this value as needed to control the width before truncation */
  font-family: IBM Plex Sans;
font-size: 14px !important;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 178.571% */
}
.inputAi{
width: 90%; 
color: #2f2f2f; margin-bottom: 10px; font-size: 14px; font-family: IBM Plex Serif; font-weight: 400; line-height: 27px; word-wrap: break-word;
  z-index: 1;
  border: none;
}
.inputAi:hover {
  border:none; /* Replace this with the desired hover border color */
}

/* Change placeholder color on hover */
.custom-input:hover::placeholder {
  color: #ACACAC; /* Replace this with the desired hover placeholder color */
}
.dropDown{
  color: #000;
  font-family: IBM Plex Serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 225% */
  cursor: pointer;
}
.loadingstate{
  display:flex;
   flex-direction:column;
    width:100%;
    height:60vh;
     justify-content:center;
     align-items:center
}
.loadingstate p {
  margin-top: 18px;
}
.dropDownIcon{
  color: #000;
  font-family: IBM Plex Serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 225% */
 margin-right: 10px;
 cursor: pointer;
}

.dropdownItem{
  background-color: rgba(0, 0, 0, 0.03);
  color: #000;
  font-family: IBM Plex Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
text-align: start;
padding: 10px 20px;
margin: 4px 0;
cursor: pointer;
}
.scrollView{
  z-index: 1;
  overflow: scroll;
  height: 100vh;
  margin: 30px 0px;
  width: 88%;
}
.scrollView::-webkit-scrollbar {
  width: 0em !important;
}

.scrollView::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.scrollView::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}
.childDiv{
  padding: 0px 0 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.mermaidData{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dot{
  width: 20px;
  height: 20px;
  border-radius: 100px;
}
.menuIcon{
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 35px;
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  background: var(--pdfbg, #fffcfc);
  position: absolute;
  top: 15px;
  right: 10px
}
.dot{
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-bottom: 5px;
  border: 1px solid #DFDFDF;
}
.tracker_title{
  color: #000;
  font-family: IBM Plex Serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
margin: 0;
margin-bottom: 10px;
text-align: start;
}
.tracker_highlights{
  color: #000;
font-family: IBM Plex Sans;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 25px; /* 178.571% */
margin: 14px 0;
}
.typing{
  color: #000;
font-family: IBM Plex Sans;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 178.571% */
}