@import "../../styles/custom/variables.scss";

.home-footer-wrap{
    background-color: $black;
    padding: 36px 0;
    @media(max-width: 899.95px){
        padding: 30px 0;
        .text-body2{
            font-size: 12px !important;
            line-height: 12px !important;
            span{
                display: inline-block;
                margin: 0 12px;
            }
        }
        .grid-container {
            margin: 0;
            width: 100%;
        }
        .grid-item-first {
            padding: 28px 0 0 0 !important;
        }
        .grid-item-sec {
            padding: 1px 0 0 0 !important;
        }
        .grid-item-third {
            padding: 10px 0 0 0 !important;
        }
    }
}