@import "../../styles/custom/variables.scss";
@import "../../styles/custom/scrollbar.scss";

.upload-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 8px;
    padding: 40px;
    background-color: $white;
    border: 2px dashed #9FAEB6;
    height: calc(100vh - 122px);
    flex-direction: column;
}
.upload-container-wrap{
    height: calc(100vh - 130px);
    .upload-wrap{
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        .ezpz-logo{
            width: 313.93px;
            height: 309.111px;
            position: relative;
            margin-left: 60px;
        }
        .ezpz-text{
            width: 411px;
            padding: 26.161px 41.307px;
            gap: 6.884px;
            margin-top: -54px;
        }
        .upload-heading{
            padding:20px 0 20px 0;
            margin:0 auto;
           
        }

        .upload-with-google:hover{
            border: 1px solid #000;
        }
        .upload-with-google{
            cursor: pointer;
            width: 329px;
            height: 56px;
            margin-left: -12px;
            border-radius: 8px;
            border: 1px solid #AFB9BF;
            background: #FFF;
            display: flex;
            gap: 15px;
            padding:15px 15px 15px 59px;
            .upload-text{
                color: #000;
                leading-trim: both;
                text-edge: cap;
                font-family: $baseFontFamily;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 23px;
                letter-spacing: -0.32px;
            }
            .ezpz-google-logo{
                width: 27px;
                height: 23px;
                flex-shrink: 0;
            }
        }
    }
    .upload-file-text{
        margin-top: 40px;
    }
}

.upload-footer{
    display:flex;
    justify-content: space-between;
    padding-top: 32px;
}

.upload-footer p{
    color: #909BA8;
    text-align: center;
    font-size: 13px;
}

.upload-google-default{
    margin:0 auto;
}

.upload-file-wrap{
    .upload-file-icon{
        img{
            @media (min-width: 1024.95px) and (max-width: 1599.95px){
                max-height: 260px;
            }
        }
        
    }
}

.upload-file-instructions{
    width: 423px;
    margin: 0px auto;
}

.upload-file-instructions p{
    color: #88919D;
    text-align: center;
    font-family: $font-weight-normal;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px; /* 0px */
    letter-spacing: -0.32px;
}
.upload-file-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.uploaded-file-area {
    display: flex;
    align-items: center;
    margin: auto auto 26px;
    max-width: 423px;
    .file-icon {
        display: flex;
        flex-direction: column;
        gap: 3px;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        img {
            opacity: 0.5;
          }
      }
      .file-size {
        color: #74868D;
      }
      .file-name-area{
        flex-grow: 1;
        .file-name {
            color: $black;
          }
        &:hover{
            cursor: default;
        }
      }
      .file-action {
        margin-left: 15px;
        .btn{
            &.icon-only {
                padding: 5px;
                width: 28px;
                height: 28px;
              }
        }
      }
      .file-edit-area {
        display: flex;
        .edit-file-name {
            font-size: 16px;
            border-bottom: 0.5px solid #8A96A1;
            border-left: none;
            border-top: none;
            border-right: none;
            width: 100%;
            min-width: 310px;
            padding-bottom: 7px;
            padding-left: 0;
            padding-right: 0;
          }
          .file-extension {
            color: #74868D;
            margin-left: 8px;
          }
      }
  }