@import "../../styles/custom/variables.scss";

.section-wrap-le{
    padding-top: 77px;
    .sec-content{
        .title{
            max-width: 438px;
            @media(max-width: 899.95px){
                max-width: 100%;
            }
        }
        .subtitle{
            margin-top: 50px;
        }
    }
    .MuiGrid-item{
        &:first-child{
            @media(max-width: 899.95px){
                order: 2;
            }
        }
        &:last-child{
            @media(max-width: 899.95px){
                order: 1;
            }
        }
    }
    .order-reverse{
        .MuiGrid-item{
            &:first-child{
                order: 2;
            }
            &:last-child{
                order: 1;
            }
        }
    }
    @media(max-width: 899.95px){
        padding-top: 38px;
        text-align: center;
    }
}