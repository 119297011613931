@import "../../styles/custom/variables.scss";
@import "../../styles/custom/scrollbar.scss";

.terms-container {
    background-color: $white;
    width: 100%;
    height: 100%;
    padding: 1rem 2rem;
    overflow-y: auto;
    border-radius: 8px;
}

.terms-container-wrap {
    padding: 40px 30px 0 30px;
    @media (max-width: 767.95px) {
        padding: 0
    }
}

.login-footer {
    display: flex;
    justify-content: space-between;
    @media(max-width: 767.95px) {
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        margin-top: 0;
    }
}

.login-footer p,
.login-footer a {
    color: #909BA8;
    text-align: center;
    font-size: 13px;
    text-decoration: none;

    .seperator {
        display: inline-block;
        margin: 0 8px;
    }
}

.terms-and-conditions {
    & ol {
        counter-reset: item
    }

    & li {
        display: block;
        padding: 1rem 0;
    }

    & li:before {
        content: counters(item, ".") " ";
        counter-increment: item
    }
}