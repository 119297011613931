@import "../../styles/custom/variables.scss";
@import "../../styles/custom/scrollbar.scss";

.dialog-wrap{
    &.dialog-sm{
        .MuiDialog-paper{
            max-width: 402px;
            width: 100%;
        }
    }
    &.dialog-md{
        .MuiDialog-paper{
            max-width: 888px;
            width: 100%;
        }
    }
    &.dialog-lg{
        .MuiDialog-paper{
            max-width: calc(100% - 200px);
            width: 100%;
            max-height: calc(100% - 150px);
            height: 100%;
        }
    }
    .MuiDialog-paper {
        box-shadow: 0px 11.686px 15.192px 0px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        background-color: #fff;
    }
    .dialog-header {
        padding: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 20px;
        border-bottom: 1px solid #D6E4F0;
        &.dialog-diagram {
            justify-content: flex-start !important;
        }
        .dialog-icon {
            max-height: 50px;
            img {
                min-height: 40px;
                max-height: 50px;
            }
        }
        .dialog-close{
            position: absolute;
            right: 32px;
            z-index: 10;
        }
    }
    .dialog-body {
        padding: 32px 36px 0;
        max-height: calc(100vh - 430px);
        overflow-y: auto;
        &.dialog-diagram {
            padding: 15px;
            max-height: 100%;
            overflow-x: auto;
        }
    }
    .dialog-disclaimer {
        text-align: center;
        border-top: 1px solid #D6E4F0;
        color: #83959F;
        .dialog-text {
            margin: 0;
            padding-top: 12px;
            padding-bottom: 15px;
            padding-left: 75px;
            padding-right: 75px;
            font-size: 12px;
            font-family: $font-weight-normal;
            line-height: 19px;
            .dialog-text-link {
                color: $black;
                font-family: $font-weight-semibold;
            }
        }
    }
    .dialog-action {
        padding: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .btn-right{
            width: 100%;
            text-align: right;
        }
    }
    .guiding-light-action{
        display: flex;
        align-items: center;
        border-top: 0.5px solid #7195A9;
        padding-top: 12px;
        margin-top: 10px;
        .pager{
            flex-grow: 1;
            display: flex;
            align-items: center;
        }
        .action-btn-wrap{
            flex-shrink: 0;
            display: flex;
            align-items: center;
        }
    }
    .action-btn {
        display: flex;
        align-items: center;
        gap: 7px;
        margin-bottom: 0;
    }
}