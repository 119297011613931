.quiz-header-wrap{
    margin-bottom: 30px;
    .title-head {
        display: flex;
        align-items: center;
        gap: 16px;
        h3 {
            flex-grow: 1;
        }
        .quiz-head-action {
            flex-shrink: 0;
        }
    }
    .selected-text-area {
        margin-top: 20px;
    }
    .quiz-counter {
        border-top: 0.5px solid #8fb3c7;
        border-bottom: 0.5px solid #8fb3c7;
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 20px 0;
        margin-top: 6px;
        .item-left {
            flex-grow: 1;
            display: flex;
            gap: 30px;
        }
        .item-right {
            flex-shrink: 0;
            display: flex;
            gap: 30px;
        }
        .item {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
    .toggle-action {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}