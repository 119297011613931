@import '../../styles/custom/variables.scss';

.toggle-btn{
    position: relative;
    display: inline-flex;
    input {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &::after, &::before {
            font-size: 14px;
            position: absolute;
            font-family: $font-weight-bold;
            top: 8px;
        }
        &::after{
            display: block;
            content: "Off";
            right: 15px;
            color: $black;
        }
        &::before {
            content: "On";
            display: none;
            left: 6px;
            color: $white;
        }
        &:checked {
            &::after{
                display: none;
            }
            &::before{
                display: block;
            }
            + label {
                background-color: $black;
                &:before{
                    left: 40px;
                    background-color: $white;
                }
            }
        }
    }
    label {
        position: relative;
        cursor: pointer;
        display :block;
        width: 69px;
        height: 34px;
        background-color: transparent;
        border-radius: 50px;
        transition: 300ms linear;
        border: 1.5px solid $black;
        &:before {
            position: absolute;
            top: 50%;
            left: 5px;
            transform: translateY(-50%);
            content: "";
            display: block;
            width: 22px;
            height: 22px;
            background-color: $black;
            border-radius: 50%;
            transition: left 300ms linear;
        }
    }
}