@import "../../styles/custom/variables.scss";

header{
    padding-top: 50px;
    position: absolute;
    width: 100%;
    background: linear-gradient(180deg, #FFC90C 0%, #FFC90C 100%);
    padding-bottom: 50px;
    z-index: 10;
    top: 0;
    .MuiContainer-root{
        display: flex;
        align-items: center;
        gap: 16px;
    }
    .header-logo {
        max-height: 35px;
        flex-grow: 1;
        img {
            max-height: 35px;
            @media(max-width: 899.95px){
                max-height: 23px;
            }
        }
        @media(max-width: 899.95px){
            max-height: 23px;
        }
    }
    @media(max-width: 1199.95px){
        padding-top: 36px;
        padding-bottom: 36px; 
    }
}