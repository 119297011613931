@import "../../styles/custom/variables.scss";

.section-wrap-framework{
    padding-top: 100px;
    .content-box{
        padding: 62px 36px 30px 36px;
        background-color: #F9F7F0;
        border-radius: 12px;
        height: 100%;
        display: flex;
        flex-direction: column;
        .icon{
            max-height: 130px;
            text-align: center;
            margin-bottom: 50px;
            flex-grow: 1;
            img{
                max-height: 130px;
                @media(max-width: 899.95px){
                    max-height: 75px;
                }
            }
            @media(max-width: 899.95px){
                max-height: 75px;
                margin-bottom: 30px;
            }
        }
        .content{
            flex-shrink: 0;
            .title{
                max-width: 160px;
                color: #332F2F;
                @media(max-width: 899.95px){
                    font-size: 14px;
                    line-height: 18px;
                    max-width: 85px;
                }
            }
            .subtitle{
                display: none;
                opacity: 0;
                @media(max-width: 899.95px){
                    text-align: center;
                }
            }
        }
        &:hover{
            padding: 35px 24px 30px 24px;
            background-color: #E7E4DD;
            .icon{
                transform: scale(0.8);
                margin-bottom: 15px;
            }
            .content{
                
                .title{
                    max-width: 100%;
                    color: #332F2F;
                    text-align: center;
                }
                .subtitle{
                    display: block;
                    opacity: 1;
                }
            }
            @media(max-width: 899.95px){
                padding: 36px 20px 18px 20px; 
            }
        }
        @media(max-width: 899.95px){
            padding: 36px 20px 18px 20px;
            border-radius: 7px;
        }
    }
    @media(max-width: 899.95px){
        padding-top: 27px;
        .MuiGrid-container{
            .MuiGrid-item{
                &:nth-child(2){
                    .content-box{
                        .icon{
                            img{
                                max-height: 56px;
                            }
                        }
                    } 
                }
                &:nth-child(3){
                    .content-box{
                        .icon{
                            img{
                                max-height: 47px;
                            }
                        }
                    } 
                }
                &:nth-child(4){
                    .content-box{
                        .icon{
                            img{
                                max-height: 55px;
                            }
                        }
                    } 
                }
            }
        }
    }
}