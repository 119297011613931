@import "../../styles/custom/variables.scss";
@import "../../styles/custom/scrollbar.scss";

.activity-container-wrap{
    background-color: $white;
    height: calc(100vh - 122px);
    margin-top: 30px;
    border-radius: 8px;
    padding: 45px 40px;
    position: relative;
    .inner-activity-container{
        overflow-y: auto;
        overflow-x: hidden;
        padding: 40px;
        max-height: calc(100vh - 210px);
        .no-activity-wrap {
            display: flex;
            justify-content: center;
            margin-top: 173px;
        }
    }
}

.activity-heading{
    color: #000;
    font-family: $font-weight-normal;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.64px;
}

.activity-days-heading{
    color: #000;
    font-family: $font-weight-normal;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.48px;
}

.activity-container{
    display:flex;
    justify-content: space-between;
}

.activity-search{
    width: 250px;
    height: 43px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 0.5px solid #CAD3DB;
    padding:10px 30px 10px 10px;
    font-family: $font-weight-normal;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
}
.activity-search::placeholder {
    color: #7A8AA1;
    font-family: $font-weight-normal;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
}

.activity-search-wrap{
    position: relative;
}
.activity-search-icon{
    position: absolute;
    left: 220px;
    top: 10px;
    z-index: 99;
}

.activity-item-heading-wrap{

}
.activity-item-heading{
    color: #000;
    font-family: $font-weight-normal;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.48px;
    text-align: center;
}

.activity-list-item-wrap{
    display: flex;
    justify-content: center;
    gap: 15px;
    padding:5px;
}

.activity-list-item{
    display: flex;
    gap: 15px;
    padding: 19px;
    
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 10px;
    background: #F2F3F3;
}

.activity-list-item-icon{
    width: 25px;
    height: 25px;
    flex-shrink: 0;
}

.activity-list-item-title{
    color: #5F6F76;
    font-family: $font-weight-medium;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}
.activity-list-item-title:hover{
    color: #000;
}
.circle-loader{
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
}