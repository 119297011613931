@import "../../styles/custom/variables.scss";

.onboarding-dialog-wrap{
    .dialog-wrap {
        &.dialog-md{
            .MuiDialog-paper{
                max-width: 805px;
            }
        }
        .dialog-header{
            padding: 32px 45px;
            justify-content: flex-start;
            gap: 12px;
            border-bottom: 1px solid #DEE7F0;
            .dialog-title{
                .text-h2{
                    font-family: $font-weight-semibold;
                }
            }
            .dialog-close{
                right: 45px;
            }
        }
        .dialog-body{
            padding: 32px 45px 0;
            max-height: calc(100vh - 260px);
        }
        .dialog-action{
            justify-content: flex-end;
            padding: 32px 45px;
        }
    }
    .onboarding-tabs-wrap{
        .tabs-wrap{
            display: flex;
            .MuiTabs-vertical{
                flex-shrink: 0;
            }
            .tab-content-wrap{
                flex-grow: 1;
                border-bottom: 1px solid #DEE7F0;
                padding-left: 45px;
            }
        }
    }
}