.header-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .logo-area {
        max-height: 31px;
        flex-grow: 1;
        img{
            max-height: 31px;
        }
    }
    .header-actions {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 16px;
        .action-icon {
            margin: 0;
            transition: all 0.3s ease-in-out 0s;
            &:hover{
                img{
                    filter: invert(63%) sepia(10%) saturate(520%) hue-rotate(158deg) brightness(88%) contrast(90%);
                }
            }
        }
    }
}