@import "../../styles/custom/variables.scss";

.banner-wrap {
  background: linear-gradient(180deg, #FFC90C 0%, #FFC90C 100%);
  min-height: 100vh;
  padding-top: 152px;
  .MuiGrid-container{
    min-height: calc(100vh - 72px);
    @media(max-width: 899.95px){
      min-height: auto;
    }
  }
  .banner-img {
    position: relative;
    display: flex;
    .form-bg{
        @media(max-width: 1100.95px){
          max-height: 240px;
        }
        @media(max-width: 899.95px){
          display: none;
        }
    }
    .ezpz-person {
        margin-left: -125px;
        @media(min-width: 1100.95px) and (max-width: 1250.95px){
          max-height: 322px;
          margin-top: 95px;
          margin-left: -120px;
        }
        @media(max-width: 1100.95px){
          max-height: 241px;
          margin-top: 40px;
          margin-left: -85px;
        }
        @media(max-width: 899.95px){
          display: none;
        }
    }
    .form-bg-mobile{
      @media(min-width: 900.95px){
        display: none;
      }
      @media(max-width: 380.95px){
        max-width: 358px;
      }
    }
    @media(max-width: 899.95px){
      max-width: 350px;
      margin: 40px auto auto;
    }
  }
  .banner-form{
    position: absolute;
    max-width: 240px;
    left: 60px;
    top: 125px;
    width: 100%;
    .custom-field {
      position: relative;
      margin-top: 15px;
      @media(max-width: 1100.95px){
        margin-top: 7px;
      }
      .form-control{
        border: none;
        background-color: transparent;
        padding: 6px 15px 6px 20px;
        background-image: url('../../assets/input-field-bg.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 33px;
        font-family: "Fredoka", sans-serif;
        color: #584848;
        &:focus ~ .input-placeholder, &.has-value ~ .input-placeholder{
          display: none;
        }
        @media(max-width: 1100.95px){
          background-size: 185px;
          padding: 6px 15px 6px 15px;
        }
        @media(max-width: 899.95px){
          background-image: url('../../assets/input-field-bg-mobile.svg'); 
          background-size: 159px;
        }
      }
      .input-placeholder{
        position: absolute;
        left: 20px;
        top: 9px;
        pointer-events: none;
        img{
          @media(max-width: 1100.95px){
            max-height: 11px;
          }
        }
        @media(max-width: 1100.95px){
          left: 15px;
          top: 8px;
        }
      }
    }
    .custom-button{
      button{
        background-color: transparent;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 0;
        padding: 0;
        cursor: pointer;
        .btn-hover-img{
          display: none;
        }
        &:hover{
          .btn-hover-img{
            display: block;
          }
          .btn-img{
            display: none;
          }
        }
        img{
          @media(max-width: 1100.95px){
            max-height: 33px;
          }
        }
        @media(max-width: 1100.95px){
          margin-top: 15px;
        }
      }
    }
    @media(max-width: 1100.95px){
      max-width: 185px;
      left: 45px;
      top: 60px;
    }
    @media(max-width: 899.95px){
      max-width: 159px;
      left: 38px;
      top: 55px;
    }
  }
  .subtitle {
    font-size: 30px;
    line-height: 38px;
    margin-top: 30px;
    @media(max-width: 1100.95px){
      font-size: 19px;
      line-height: 26px;
      margin-top: 36px;
    }
  }
  .tooltip-wrap{
    .MuiTooltip-tooltip{
      font-family: "Happy Monkey", system-ui;
      padding: 12px;
      min-width: auto;
      white-space: nowrap;
      font-size: 16px;
      line-height: 20px;
      max-width: 100%;
    }
    .tooltip-content{
      display: flex;
      align-items: center;
      gap: 10px;
    }

  }
  @media(max-width: 899.95px){
    min-height: auto;
    padding-top: 145px;
    padding-bottom: 35px;
    text-align: center;
  }
}

// .unclickable {
//   pointer-events: none;
//   opacity: 0.6;
// }