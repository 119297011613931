@import "../../styles/custom/variables.scss";

.pagination-wrap{
    padding-bottom: 13px;
    display: flex;
    align-items: center;
    .pager-btn {
        height: 24px;
        width: 24px;
        .btn {
            padding: 0;
            background-color: transparent;
            width: 24px;
            height: 24px;
            img {
                margin-top: 0;
              }
            &:disabled{
                cursor: not-allowed;
                img{
                    filter: invert(83%) sepia(9%) saturate(285%) hue-rotate(150deg) brightness(93%) contrast(89%)
                }
            }
          }
          &.prev-btn{
            .btn{
                transform: rotate(-180deg);
            }
          }
    }
    // .MuiPagination-ul {
    //     li{
    //         .MuiPaginationItem-root {
    //             width: auto;
    //             height: auto;
    //             min-width: auto;
    //             padding: 0;
    //             font-size: 18px;
    //             position: relative;
    //             margin: 0;
    //             &.Mui-selected{
    //                 background-color: transparent;
    //                 &:hover{
    //                     background-color: transparent;
    //                 }
    //             }
    //             &:hover{
    //                 background-color: transparent;
    //             }
    //             .MuiTouchRipple-root{
    //                 display: none;
    //             }
    //             &.MuiPaginationItem-page{
    //                 &::before {
    //                     display: block;
    //                     content: "/";
    //                     margin: 0 5px;
    //                 }
    //             }
    //             &.MuiPaginationItem-previousNext
    //             {
    //                 width: 24px;
    //                 height: 24px;
    //                 svg{
    //                     width: 24px;
    //                     height: 24px;
    //                 }
    //             }
    //         }
    //         &:nth-child(2){
    //             .MuiPaginationItem-root {
    //                 &.MuiPaginationItem-page{
    //                     &::before {
    //                         display: none;
    //                     }
    //                 } 
    //             }
    //         }
    //     }
    // }
}