@import '../../styles/custom/variables.scss';

.base-layout-wrap {
    display: flex;
    align-items: stretch;
    min-height: 100vh;
    background-color: $bodyBg;
    .left-area {
        flex-grow: 1;
        padding: 25px 25px 25px 40px;
        &.no-right-area {
            padding: 30px 40px 30px 40px;
            @media (max-width: 767.95px){
             padding: 30px;       
            }
        }
    }
    .right-area {
        flex-shrink: 0;
        width: 462px;
        background-color: $white;
        min-height: 100vh;
        padding-right: 42px;
        @media(min-width: 1599.95px){
            width: 500px;
        }
        .logo-area {
            max-height: 120px;
            text-align: center;
            margin-bottom: 35px;
            img{
                max-height: 120px;
            }
        }
        .logo-bottom-text {
            padding: 6px 35px 17px 31px;
            font-family: $baseFontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 21.28px;
            text-align: center;
            color: #3C494E;
            letter-spacing: -0.02rem;
          }
        .content-container{
            padding: 27px 0 27px 32px;
            height: 100%;
            display: flex;
            flex-direction: column;
            max-height: 100vh;
        }
    }
}