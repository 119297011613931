@import "../custom/variables.scss";

.pdf-select-text-actions{
    background-color: $white;
  box-shadow: 0px 2px 16px 7px rgb(49 50 61 / 39%);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 12;
  gap: 14px;
  border: 1px solid #95abc1;
}
// .PdfHighlighter__tip-container{
//     &::before{
//         display: block;
//         content: "";
//         background-color: rgba(62, 75, 89, 0.53);
//         width: 100%;
//         height: 100%;
//         position: fixed;
//         top: 0;
//         left: 0;
//         z-index: 10;
//         pointer-events: none;
//         user-select: none;
//     }
// }