@import '../../styles/custom/variables.scss';

.text-area-wrap {
    position: relative;
    .action-btn {
        position: absolute;
        top: 12px;
        right: 18px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .socratic-btn{
            &:hover{
                border: 1px solid $black;
                background-color: transparent;
                color: $black;
                img{
                    filter: none;
                }
            }
        }
    }
}
.form-control {
    width: 100%;
    border: 1px solid #D0DBE7;
    border-radius: 8px;
    padding: 12px 16px;
    font-family: $font-weight-medium;
    margin: 0;
    resize: none;
    &.has-action-btn{
        padding: 12px 55px 12px 16px;
    }
    &:focus, &:focus-visible{
        border-color: $black;
        outline: none;
    }
}
