@import "../../styles/custom/variables.scss";
@import "../../styles/custom/scrollbar.scss";

.restrict-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    flex-grow: 1;
    max-height: calc(100vh - 148px);
    width: 100%;
    padding: 40px;
    @media (max-width: 767.95px){
        padding: 30px;
        max-height: calc(100vh - 135px);               
    }
}
.restrict-container-wrap{
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    padding: 40px 30px 0 30px;
    @media (max-width: 767.95px){
     padding: 0               
    }
    .restrict-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        @media(max-width: 767.95px){
            position: relative;
            top: 192px;
        }
        .text-bolder {
            font-family: $font-weight-bold;
            font-size: 20px;
            line-height: 26px;
            letter-spacing: -0.02em;
        }
        .text-lighter {
            font-family: $font-weight-normal;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: -0.02em;
        }
    }
}

.restrict-footer{
    display:flex;
    justify-content: space-between;
    @media(max-width: 767.95px){
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        margin-top: calc(100% - 26px);
    }
}

.restrict-footer p, .restrict-footer a{
    color: #909BA8;
    text-align: center;
    font-size: 13px;
    text-decoration: none;
    .seperator {
        display: inline-block;
        margin: 0 8px;
    }
}
