@import "../../styles/custom/variables.scss";

.loader{
    .jumping-dots{
        span {
            position: relative;
            margin-left: auto;
            margin-right: auto;
            animation: jump 1s infinite;
            display: inline-block;
        }
        .dot-1,
        .dot-2,
        .dot-3{
            width: 4px;
            height: 4px;
            margin-right: 3px;
            border-radius: 50%;
            background-color: $black;
        }
        .dot-1 {
            animation-delay: 200ms;
        }
        .dot-2 {
            animation-delay: 400ms;
        }
        .dot-3 {
            animation-delay: 600ms;
        }
    }
}

/*Loader Animation*/
@keyframes jump {
    0% {
      bottom: 0px;
    }
    20% {
      bottom: 3px;
    }
    40% {
      bottom: 0px;
    }
}