@import "../../styles/custom/variables.scss";

.quiz-questions-wrap{
    .ques-item {
        margin-top: 24px;
        &:first-child {
            margin-top: 0;
        }
    }
    .question {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;
        .ques-left {
            flex-grow: 1;
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .question-text {
            padding-top: 7px;
        }
        .ques-actions {
            flex-shrink: 0;
        }
    }
    .question-options{
        padding-left: 32px;
        .options-list{
            margin: 0;
            list-style: none outside none;
            padding-left: 0;
        }
        .answer, .explaination{
            margin-top: 14px;
            max-width: 80%;
        }
        .answer p, .explaination p{
            display: inline;
            &:last-child{
                margin-left: 5px;
            }
        }
    }
}