@import "../../styles/custom/variables.scss";

.feedback-dialog-wrap{
    .dialog-wrap {
        .dialog-header{
            display: none;
        }
        .dialog-body{
            padding: 0;
            max-height: 100%;
            overflow: hidden;
            height: auto;
        }
    }
    .feedback-dialog-container {
        display: flex;
        padding-top: 40px;
    }
    .dialog-left-area{
        max-height: 559px;
        flex-shrink: 0;
    }
    .dialog-right-area{
        flex-grow: 1;
        padding: 0px 40px 40px 16px;
    }
    .feedback-form-area{
        .title-wrap {
            display: flex;
            flex-direction: column;
            gap: 4px;
            border-bottom: 1px solid #D6E4F0;
            padding-bottom: 25px;
            margin-bottom: 25px;
            .title {
                display: flex;
                align-items: center;
                gap: 16px;
            }
          }
          .form-group {
            display: flex;
            gap: 25px;
            .input-label{
                flex-shrink: 0;
            }
            .field-wrap{
                flex-grow: 1;
                .action-area {
                    margin-top: 30px;
                    display: flex;
                    gap: 16px;
                }
            }
            .field-child {
                textarea {
                    height: 300px;
                }
            }
          }
    }
}