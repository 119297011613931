@import '../../styles/custom/variables.scss';

.btn{
    font-family: $font-weight-bold;
    font-size: 14px;
    letter-spacing: -0.28px;
    cursor: pointer;
    border-radius: 7px;
    padding: 10px;
    font-weight: normal;
    text-transform: none;
    min-width: 124px;
    margin: 0;
    &.btn-primary{
        background-color: $black;
        color: $white;
        border-color: $black;
        &:hover{
            background-color: $blueBayoux;
        }
    }
    &.outline-btn{
        border: 1px solid $black;
        background-color: transparent;
        color: $black;
        &:hover{
            background-color: $blueBayoux;
            border-color: $blueBayoux;
            color: $white;
            img{
                filter: invert(98%) sepia(33%) saturate(2%) hue-rotate(194deg) brightness(119%) contrast(100%);
            }
        }
    }
    &.full-width{
        width: 100%;
        display: block;
    }
    &.icon-only{
        border-radius: 5px;
        min-width: auto;
        padding: 7px;
    }
    &.with-icon{
        padding: 10px 13px;
        display: flex;
        gap: 10px;
        border-radius: 7px;
    }
    &.exception {
        &:hover {
            background-color: #000 !important;
        }
    }
}