@import "../../styles/custom/variables.scss";
@import "../../styles/custom/scrollbar.scss";

.login-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    flex-grow: 1;
    max-height: calc(100vh - 148px);
    width: 100%;
    padding: 40px;
    @media (max-width: 767.95px){
        padding: 30px;
        max-height: calc(100vh - 135px);               
    }
}
.login-container-wrap{
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    padding: 40px 30px 0 30px;
    @media (max-width: 767.95px){
     padding: 0               
    }
    .login-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        .logo-area {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .ezpz-logo{
                max-height: 359px;
                position: relative;
                z-index: 1;
                @media (min-width: 1024.95px) and (max-width: 1599.95px){
                    max-height: 288px;
                }
                @media (max-width: 767.95px){
                    max-height: 220px;
                }
            }
            .ezpz-text{
                width: auto;
                margin-top: -26px;
                background-color: $white;
                max-height: 114px;
                @media (min-width: 1024.95px) and (max-width: 1599.95px){
                    max-height: 74px;
                    margin-top: -17px;
                }
            }
        }
        
        
        .login-heading{
            padding:40px 0;
            margin:0 auto;
            img{
                @media (max-width: 767.95px){
                    max-height: 26px;
                }
            }
           
        }

        .login-with-google:hover{
            border: 1px solid #000;
        }
        .login-with-google{
            cursor: pointer;
            width: 329px;
            height: 56px;
            margin-left: -12px;
            border-radius: 8px;
            border: 1px solid #AFB9BF;
            background: #FFF;
            display: flex;
            gap: 15px;
            padding:15px 15px 15px 59px;
            .login-text{
                color: #000;
                leading-trim: both;
                text-edge: cap;
                font-family: $baseFontFamily;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 23px;
                letter-spacing: -0.32px;
            }
            .ezpz-google-logo{
                width: 27px;
                height: 23px;
                flex-shrink: 0;
            }
        }
    }

    .login-footer-text{
        width: 63%;
        margin: 0 auto;
    }
    .login-footer-text p{
        color: #B0BAC6;
        text-align: center;
        font-size: 13px;
    }
}

.login-footer{
    display:flex;
    justify-content: space-between;
    padding-top: 32px;
    @media(max-width: 767.95px){
        flex-direction: column;
        gap: 8px;
        justify-content: center;
    }
}

.login-footer p, .login-footer a{
    color: #909BA8;
    text-align: center;
    font-size: 13px;
    text-decoration: none;
    .seperator {
        display: inline-block;
        margin: 0 8px;
    }
}

.login-google-default{
    margin:0 auto;
    max-width: 212px;
}