@import './variables.scss';

*,
::after,
::before {
    box-sizing: border-box;
}

.base-layout-wrap,
body {
    font-family: $baseFontFamily;
}

.cp {
    cursor: pointer;
}

.mt-0 {
    margin-top: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.m-0 {
    margin: 0;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-6 {
    margin-bottom: 6px;
}

.mb-8 {
    margin-bottom: 8px;
}
.mb-10 {
    margin-bottom: 10px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mt-6 {
    margin-top: 6px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px;
}

.text-right {
    text-align: right;
}

.text-dark {
    color: $black;
}

.text-grey {
    color: $grey;
}

.text-grey-light {
    color: $textGreyLight;
}

.text-grey-light-a {
    color: #656971;
}

.text-danger {
    color: $danger;
}

.text-success {
    color: $success;
}

.text-white {
    color: $white;
}

.decoration {
    text-decoration: underline;
}

.text-decoration-none {
    text-decoration: none;
}

.text-justify {
    text-align: justify;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}
.d-block{
    display: block;
}

.lh-22 {
    line-height: 22px !important;
}

.lh-20 {
    line-height: 20px !important;
}
.lh-18 {
    line-height: 18px !important;
}

.fw-normal {
    font-family: $font-weight-normal;
}

.fw-medium {
    font-family: $font-weight-medium;
}

.fw-sbold {
    font-family: $font-weight-semibold;
}

.fw-bold {
    font-family: $font-weight-bold;
}

.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5 {
    font-weight: normal;
}

.text-fw-normal {
    font-weight: normal;
}

.text-muted {
    color: $textMuted;
}

.text-h2 {
    font-size: 28px;
    letter-spacing: -0.561px;
}

.text-h3 {
    font-size: 24px;
    letter-spacing: -0.48px;
}

.text-h4 {
    font-size: 18px;
    letter-spacing: -0.36px;
    line-height: 23px;
}

.text-h5 {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
}

.text-body1 {
    font-size: 16px;
    line-height: 22.88px;
    letter-spacing: 0;
}

.text-body2 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    display: block;
}

.text-small {
    font-size: 12px;
    line-height: 13.08px;
    letter-spacing: 0;
    display: block;
}

.text-caption {
    font-size: 10px;
    letter-spacing: 0;
}

.zoom-btn {
    position: absolute;
    top: 105px;
    right: 32px;
    display: flex;
    align-items: center;
    z-index: 1000;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;

    .btn {
        &.icon-only {
            width: 35px;
            height: 35px;
            padding: 4px 10px;
            background-color: $white;
            border: 1px solid #A6ACB1;

            // &:hover {
            //     border-color: $black;
            // }
        }
    }
}

.unclickable {
    pointer-events: none;
    opacity: 0.6;
}

.user-highlight-none {
    -khtml-user-select: none !important;
    -o-user-select: none !important;
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    user-select: none !important;
}

.va-middle{
    vertical-align: middle;
}
.webviewer{
    font-family: $font-weight-bold;
}