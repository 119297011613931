@import "../../styles/custom/variables.scss";
@import "../../styles/custom/scrollbar.scss";

.drawer-wrap{
    .MuiModal-backdrop{
        background-color: rgba(62, 75, 89, 0.53); 
    }
    &.light-backdrop{
        .MuiModal-backdrop{
            background-color: rgba(243, 243, 243, 0.75);
        }
    }   
    .MuiPaper-root{
        box-shadow: -12px 0px 19px 0px rgba(0, 0, 0, 0.25);
        overflow: hidden; 
    }
    &.size-sm{
        .MuiPaper-root{
            width: 462px;
        }
        .drawer-content-inner{
            padding-right: 0;
        }
    }
    &.size-md{
        .MuiPaper-root{
            width: 70%;
        }
        .drawer-content {
            padding: 30px 65px 24px;
        }
        &.has-chat{
            .drawer-content {
                padding: 30px 65px 0;
            } 
        }
    }
    &.has-chat{
        .drawer-content-inner {
            max-height: calc(100vh - 275px);
            min-height: calc(100vh - 275px);
        }
    }
    &.quiz-drawer{
        .drawer-content-inner {
            max-height: calc(100vh - 191px);
            min-height: calc(100vh - 191px); 
        }
    }
    &.quiz-action{
        .drawer-content-inner {
            max-height: calc(100vh - 252px);
            min-height: calc(100vh - 252px); 
        }
    }
    .drawer-content {
        padding: 30px 32px;
    }
    .drawer-content-inner {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 125px);
        min-height: calc(100vh - 125px);
        display: flex;
        flex-direction: column;
        padding-right: 32px;
        .content-area{
            flex-grow: 1;
        }
        .chat-area{
            flex-shrink: 0;
            margin-top: 0;
            position: relative;
            // padding-bottom: 58px;
            .user-chat{
                margin-top: 40px;
            }
        }
    }
    .back-btn{
        display: block;
        margin-bottom: 45px;
    }
    .chat-area{
        .info-area{
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: flex-end;
            margin-bottom: 30px;
            margin-top: 30px;
        }
        .chat-bubble-wrap{
            &.msg-received{
              .chat-bubble {
                background-color: $bodyBg;
              }
            }
            &.msg-sent{
              text-align: right;
              .chat-bubble {
                border: 0.5px solid $borderColor;
                text-align: left;
              }
            }
            &.bubble-suggestion{
                // position: absolute;
                // bottom: -30px;
                // right: 0;
                margin-top: 140px;
                .chat-bubble{
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
                }
            }
            &.chat-suggestion-link{
                .chat-bubble {
                    background-color: #F0F4F5;
                    border: 1px solid $black;
                    padding: 12px 17px 12px 17px;
                    align-items: flex-start;
                    text-align: left;
                    img{
                        margin-top: 6px;
                    }
                  }
            }
        }
        .chat-bubble {
            padding: 15px;
            margin-bottom: 8px;
            // max-width: 380px;
            border-radius: 8px;
            display: inline-flex;
            align-items: center;
            gap: 10px;
            .text-body2 {
                line-height: 22px;
                font-size: 15px;
              }
        }
        .need-suggestion {
            padding: 15px 20px;
            background-color: $black;
            color: $white;
        }
    }
    .chat-typing-area{
        margin-top: 16px;
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 30px 65px 24px;
        .help-text {
          position: absolute;
          bottom: 4.5px;
          left: 17px;
          background-color: $white;
          width: calc(100% - 35px);
          padding-bottom: 10px;
          padding-top: 5px;
        }
        .form-control{
          max-height: 128px;
          padding: 12px 55px 30px 16px;
        }
    }
    .quiz-action-area {
        padding: 0 65px 24px;
        display: flex;
        align-items: center;
        gap: 16px;
    }
}