@import "../../styles/components/drawer-content.scss";
@import "../../styles/custom/scrollbar.scss";

.history-list-wrap {
    height: 100%;
    .history-header {
        background-color: #F2F2F2;
        border-radius: 8px 8px 0 0;
        padding: 13px 17px;
        display: flex;
        align-items: center;
        p{
            &:first-child{
                flex-grow: 1;
            }
            &:last-child{
                flex-shrink: 0;
            }
        }
    }
    // .history-list-area {
    //     border: 1px solid #D0DBE7;
    //     border-top-color: transparent;
    //     padding: 22px 10px 22px 20px;
    //     border-radius: 0 0 8px 8px;
    // }
    .no-quizes-wrap {
        display: flex;
        justify-content: center;
        margin-top: 159px;
    }
    .list-area-inner {
        max-height: calc(100vh - 60px);
        // min-height: calc(100vh - 260px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .list-item {
        display: flex;
        align-items: center;
        background-color: #F5F6F6;
        padding: 20px;
        border-radius: 10px;
        margin-top: 11px;
        transition: all 0.3s ease-in-out 0s;
        margin-right: 30px;
        &:hover{
            background-color: #E1E8EA;
        }
        .list-item-content {
            flex-grow: 1;
            p{
                &:nth-child(2){
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 236px;
                }
            }
        }
        .list-item-meta {
            flex-shrink: 0;
            text-align: right;
            .list-item-action{
                position: relative;
                top: 0px;
            }
            .date {
                color: #787E85;
                padding-top: 8px;
            }
        }
        &:first-child{
            margin-top: 0;
        }
    }
}