.page-header-wrap{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    .title-area{
        flex-grow: 1;
    }
    .action-area{
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 14px;
    }
}