@import "../../styles/custom/variables.scss";

.security-section-wrap{
    padding-top: 120px;
    padding-bottom: 120px;
    .sec-content{
        .text-h2{
            @media(max-width: 899.95px){
                text-align: center;
                margin-top: 0;
            }
        }
    }
    .sec-img{
        @media(max-width: 899.95px){
            max-height: 267px;
        }
        img{
            @media(max-width: 899.95px){
                max-height: 267px;
            }
        }
    }
    .list{
        list-style: none outside none;
        padding-left: 0;
        margin-top: 58px;
        margin-bottom: 0;
        li{
            position: relative;
            padding-left: 50px;
            font-size: 20px;
            line-height: 32px;
            margin-top: 20px;
            &::before{
                display: block;
                content: "";
                background-image: url('../../assets/arrow-y-right.svg');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 32px;
                height: 32px;
                position: absolute;
                left: 0;
                @media(max-width: 899.95px){
                    width: 20px;
                    height: 20px;
                }
            }
            @media(max-width: 899.95px){
                font-size: 16px;
                line-height: 22px;
                margin-top: 32px;
                padding-left: 34px;
            }
        }
        @media(max-width: 899.95px){
            margin-top: 32px;
        }
    }
    @media(max-width: 899.95px){
        padding-top: 53px;
        padding-bottom: 64px;
    }
}