.landingPage{
  width: 100%;
  overflow-x: hidden;
  background-color:#FAF5EA;
}
.landingPage::-webkit-scrollbar {
  width: 0em;
}

.landingPage::-webkit-scrollbar-track {
  background-color: transparent;
}

.landingPage::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.main_div {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #FAF5EA;
  margin: 0;
  padding: 0px 0 40px 0;
}
.home_image{
width: 50%;
}
.flexData{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  padding: 0 20px;
}
.subBlock{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-bottom: -59px;
}
.subHeading{
  color: #F96033;
text-align: center;
font-family: IBM Plex Serif;
font-size: 2.5rem;
font-style: normal;
font-weight: 700;
text-align: start;
margin: 0;

}
.mainHeading{
  color: #0F172A;
  text-align: center;
  font-family: IBM Plex Serif;
  font-size: 16.875rem;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  text-align: start;
}
.flexDisplay{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

}
.blockDisplay{
  display: block;
  width: 100%;


}
.paragraph{
  color: #0F172A;
font-family: IBM Plex Serif;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: 0%; /* 36px */
letter-spacing: -0.45px;
margin-top: 51px;

}
.btn{
  display: inline-flex;
margin-top: 15px;
height: auto;
padding: 20px 70px;
flex-direction: column;
align-items: center;
border-radius: 50px;
background: #F96033;
border: none;
color: #ebebeb;
font-family: IBM Plex Serif;
font-size: 30px;
font-style: normal;
cursor: pointer;
border: none;
text-decoration: none;
outline: none;
}
.btn:focus {
  outline: none !important;
  border: none !important;
}
.btn:focus-visible {
  outline: none !important;
  border: none !important;
}
.btn:hover {
  outline: none !important;
  border: none !important;
}
.hovered {
 
  transform: scale(1.1);
}
.story_div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #F3EDDF;
  margin: 0;
  padding: 60px 0px;
}
.mini_title{
  color: #F96033;
font-family: IBM Plex Serif;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
padding-bottom: 15px;
}
.story_title{
  color: #0F172A;
  font-family: IBM Plex Serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
margin: 0;
}
.story_desc{
  color: #0F172A;
font-family: IBM Plex Serif;
font-size: 23px;
font-style: normal;
font-weight: 400;
line-height: 34px;
  margin: 0;
  padding-bottom: 3rem;
}
.story_describe{
  color: #F96033;
  font-family: IBM Plex Serif;
  font-size: 32px;
  font-style: italic;
  font-weight: 600;
  line-height: 35px;
}
.story_gif{
  width: 100%;
}
.centerBlock{
  display: flex;
  justify-content:center ;
  align-items: center;
  height: 100%;
  width: 95%;
}

.feature{
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content:center ;
  align-items: center;
  margin: 0;
  padding: 60px 0px;
}
.feature_title{
  color: #F96033;
  font-family: IBM Plex Serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.feature_des{
  color: #0F172A;
  font-family: IBM Plex Serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal; /* 155% */
  letter-spacing: -0.6px;
  margin: 30px 0;
}
.feature_option_main{
  width: 80%;
  color: #F96033;
  font-family: IBM Plex Serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin: 10px 0;
  background-color:#FAF5EA ;
}
.features_option_parent{
  display: flex;
  flex-direction: column;
  margin-top: 75px;
}
.feature_option{
  width: 80%;
  color: #0F172A;
  font-family: IBM Plex Serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin: 10px 0;
  background-color:#FAF5EA ;
}
.feature_option_main:hover{
  color: #F96033;
font-weight: 600;
}
.feature_option:hover{
font-weight: 600;
}
.feature_icon{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}
.feature_notes{
  color: #0F172A;
font-family: IBM Plex Serif;
font-size: 23px;
font-style: normal;
font-weight: 400;
height: 120px;
line-height: 34px; /* 147.826% */
margin: 0;
}
.feature_gif{
  width: 40%;
}
.placeholder{
  height: 350px;
  background: #F3EDDF;
}
.footer{
  color: #0F172A;
  text-align: center;
  font-family: IBM Plex Serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 338.462% */
}

@media (max-width: 768px) {
  .subBlock{
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
  .home_image{
    width: 90%;
    }
  .subHeading{
  font-size: 1.5rem;
  margin: 0;
  }
  .mainHeading{
    padding-top: 4rem;
    font-size: 4.875rem;
  }
  .flexDisplay{
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    flex-direction: column;
  }
  .blockDisplay{
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    flex-direction: column;
  }
  .paragraph{
  font-size: 20px;
  margin: 0;
  width: 60%;
  text-align: center;
  line-height: normal;
  margin-top: 25px;
  }
  .btn{
  margin-top: 39px;
  padding: 13px 85px;
  height: 40px;
  }
  .story_div {
    margin: 0;
    padding: 40px 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mini_title{
  font-size: 18px;
  }
  .story_title{
  font-size: 18px;
  line-height: normal;
  }
  .story_desc{
    font-size: 16px;
    line-height: normal;
    padding-bottom: 2rem;
  }
  .story_describe{
  font-size: 18px;
  line-height: normal;
  }
  .story_gif{
    width: 100%;
  }
  .features_option_parent{
    display: flex;
   flex-direction: row;
   overflow: scroll;
   margin: 0;
  }
  .features_option_parent::-webkit-scrollbar {
    width: 0em;
  }
  
  .features_option_parent::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .features_option_parent::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .centerBlock{
    height: 100%;
    width: 85%;
  }
  
  .feature{
    margin: 0;
    padding: 40px 0 0px 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .feature_title{
  font-size: 18px;
  line-height: normal;
  margin-bottom: -13px;
  }
  .feature_des{
    color: #0F172A;
  font-family: IBM Plex Serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.6px;

  }
  .feature_option_main{
  font-size: 16px;
  width:max-content;
  background-color: #F3EDDF;
  display: flex;
  height: 40px;
  width: 250px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right:10px;
  }
  .feature_option{
  font-size: 16px;
  width:max-content;
  background-color: #F3EDDF;
  display: flex;
  width: 250px;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  margin-right:10px;
  border-radius: 8px;
  }
  .feature_icon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .feature_notes{
  font-size: 16px;
 margin-top: 20px;
 height: 100px;
 line-height: normal;
  }
  .placeholder{
    width: 100%;
    height: 350px;
    background: #F3EDDF;
  }
  .footer{
    color: #0F172A;
  text-align: center;
  font-family: IBM Plex Serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 400% */
  padding: 10px 0 10px 0px;
  }
  
}
