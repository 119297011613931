@import '../../styles/custom/variables.scss';
@import "../../styles/custom/scrollbar.scss";

.chat-window-wrap {
    padding: 27px 0 27px 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
  .chat-area-wrap {
    flex-grow: 1;
    height: calc(100vh - 265px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .chat-area{
    padding-right: 30px;
  }
  .chat-logo-area {
    max-height: 120px;
    text-align: center;
    margin-bottom: 35px;
    img{
        max-height: 120px;
    }
  }
  .logo-bottom-text {
    padding: 6px 35px 17px 31px;
    font-family: $font-weight-normal;
    font-style: normal;
    font-size: 16px;
    line-height: 21.28px;
    text-align: center;
    color: #3C494E;
    letter-spacing: -0.02rem;
  }
  .chat-bubble-wrap{
    &.msg-received{
      .chat-bubble {
        background-color: $bodyBg;
      }
      .highlight-item{
        .chat-bubble {
          background-color: #F5F6F6;
          &:hover{
            background-color: #E1E8EA;
          }
        }
      }
    }
    &.msg-sent{
      text-align: right;
      .chat-bubble {
        border: 0.5px solid $borderColor;
        background-color: $white;
        text-align: left;
      }
    }
    .highlight-item{
      padding-right: 30px;
      .chat-bubble {
        margin-bottom: 22px;
        cursor: pointer;
        transition: all 0.3s ease-in-out 0s;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
      }
      .item{
        .selected-text{
          margin-top: 5px;
        }
        .content-brief{
          margin-top: 10px;
        }
      }
      &:last-child{
        .chat-bubble {
          margin-bottom: 0;
        }
      }
    }
  }
  .chat-bubble {
    padding: 18px;
    margin-bottom: 8px;
    // max-width: 290px;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    .text-body2 {
        line-height: 22px;
        font-size: 15px;
      }
    .response-list{
      margin-top: 0;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .timestamp {
      width: 100%;
      display: flex;
      justify-content: end;
      .timestamp-text {
        margin: 0 ;
        color: #787E85;
        font-family: $font-weight-normal;
        font-size: 11px;
        line-height: 12.38px;
        letter-spacing: -0.02rem;
      }
    }
  }
  .chat-suggestions-link{
    margin-top: 24px;
    .chat-bubble{
      background-color: $white;
      border: 0.5px solid $borderColor;
      box-shadow: 0px 2px 4px 0px rgba($black, 0.25);
      margin-bottom: 12px;
    }
  }
  .chat-typing-area{
    margin-top: 16px;
    .help-text {
      position: absolute;
      bottom: 4px;
      left: 17px;
      background-color: $white;
      width: calc(100% - 35px);
      padding-bottom: 10px;
      padding-top: 5px;
    }
    .form-control{
      max-height: 128px;
      padding: 12px 55px 30px 16px;
    }
  }
  .clear-chat {
    margin-bottom: 30px;
    .clear-btn {
      border: 1px solid #000;
      color: #5F6F76;
      font-size: 14px;
      letter-spacing: -0.28px;
      padding: 7px;
      display: inline-flex;
      align-items: center;
      border-radius: 5px;
      gap: 10px;
    }
    .btn-icon {
      max-height: 16px;
    }
  }
  &.scrollable{
    overflow-y: auto;
  }
  .chat-bot-icon {
    display: flex;
    justify-content: space-between;
    color: #3C494E;
    .chat-bot-icon-magic {
      width: 22.61px;
    }
  }
  .no-highlights-wrap {
    display: flex;
    justify-content: center;
    margin-top: 159px;
  }
}
