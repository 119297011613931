@import "../custom/variables.scss";

.content-container{
    .title-area{
        .text-h4{
            line-height: 28px;
        }
        .text-body1{
            font-size: 15px;
        }
    }
    .selected-text{
        // color: #3D4146;
        color: #494d53;
    }
    .content-brief{
        .text-body1{
            font-size: 15px;
            color: #494d53;
        }
        margin-top: 30px;
        img{
            margin-top: 16px;
        }
    }
    .action-area {
        border-top: 0.5px solid #7195A9;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 15px;
        padding-bottom: 10px;
        .action-left {
            display: flex;
            align-items: center;
            gap: 16px;
            flex-grow: 1;
        }
        .action-right{
            display: flex;
            align-items: center;
            gap: 16px;
            flex-shrink: 0;
        }
        .action-btn {
            display: flex;
            align-items: center;
            gap: 7px;
            margin-bottom: 0;
            color: $black;
            text-decoration: none;
            position: relative;
            z-index: 10;
            padding: 10px 13px;
            border-radius: 7px;
            border: 0.5px solid transparent;
            transition: all 0.3s ease-in-out 0s;
            .btn-text{
                font-size: 15px;
            }
            &:hover{
                border-color: #94A6B6;
            }
        }
    }
    .action-dropdown {
        position: relative;
        min-width: 160px;
        &.open{
            .action-btn{
                z-index: 1000;
                &:hover{
                    border-color: transparent;
                }
            }
            // .btn-style{
            //     min-width: 112px;
            // }
            // .btn-length{
            //     min-width: 83px;
            // }
            // .btn-regenrate{
            //     min-width: 128px;
            // }
            .action-list {
                display: block;
                opacity: 1;
                z-index: 100;
                background-color: $white;
                min-width: 160px;
            }
            .drop-icon{
                transform: rotate(0deg);
            }
        }
        .action-list {
            position: absolute;
            left: 0;
            top: 0px;
            margin: 0;
            list-style: none outside none;
            width: max-content;
            padding: 40px 10px 5px 30px;
            border: 0.5px solid #94A6B6;
            border-radius: 8px;
            display: none;
            transition: all 0.3s ease-in-out 0s;
            opacity: 0;
            width: 100%;
            li{
                a{
                    display: block;
                    border-top: 0.5px solid #CAD3DB;
                    padding: 6px 0;
                    text-decoration: none;
                    transition: all 0.3s ease-in-out 0s;
                    &:hover{
                        color: $black;
                    }
                }
            }
        }
        .drop-icon{
            transition: all 0.3s ease-in-out 0s;
            transform-origin: center center;
        }
    }
    
}