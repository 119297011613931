@import "../../styles/custom/variables.scss";

.checkbox-btn {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    margin-bottom: 5px;
    font-size: 14px;
    color: #636B80;
    line-height: 20px;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 13px;
        width: 13px;
        background-color: transparent;
        border: 2px solid #D1D6DB;
        &::after {
            display: none;
            content: "";
            position: absolute;
            background-image: url('../../assets/checkbox-tick.svg');
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 15px;
            height: 11px;
            left: 0;
            top: -1px;
        }
    }
    input:checked ~ .checkmark {
        &::after{
            display: block;
        }
    } 
  }