@import "../../styles/custom/variables.scss";
@import "../../styles/custom/scrollbar.scss";

.pdf-container-wrap{
    position: absolute;
    width: calc(100% - 527px);
    top: 161px;
    left: 40px;
    background-color: $white;
    height: calc(100% - 181px);
    border-radius: 8px;
    padding: 24px 12px 12px 12px;
    z-index: 100;
    @media (max-width: 920px) {
        top: 200px;
    }
    @media (min-width: 1600px) {
        width: calc(100% - 565px);
    }
    @media (min-width: 1920px) {
        width: calc(100% - 565px);
    }
    .inner-container{
        overflow-y: hidden; /*Use auto for container scoll visibility*/
        overflow-x: hidden;
        height: 100%;
    }
}