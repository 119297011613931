@import "../../styles/custom/variables.scss";
@import "../../styles/custom/scrollbar.scss";

.privacy-container{
    background-color: $white;
    width: 100%;
    height: 100%;
    padding: 1rem 2rem;
    overflow-y: auto;
    border-radius: 8px;
}
.privacy-wrap{  
    padding: 40px 30px 0 30px;
    @media (max-width: 767.95px){
     padding: 0               
    }
    .login-wrap{ 
        width: 100%;
        @media(max-width: 767.95px){
           
        }
    }
}

.privacy-footer{
    display:flex;
    justify-content: space-between;
    @media(max-width: 767.95px){
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        margin-top: 0;
    }
}

.privacy-footer p, .privacy-footer a{
    color: #909BA8;
    text-align: center;
    font-size: 13px;
    text-decoration: none;
    .seperator {
        display: inline-block;
        margin: 0 8px;
    }
}
