@import '../../styles/custom/variables.scss';

.section-title-wrap{
    .sec-title{
        .title{
            max-width: 485px;
            margin: auto;
            @media(max-width: 899.95px){
                max-width: 100%;
            }
        }
        .subtitle{
            font-size: 27px;
            margin-top: 17px;
            @media(max-width: 899.95px){
                font-size: 16px;
                line-height: 22px;
                margin-top: 33px;
            }
        }
    }
    .sec-img{
        margin-bottom: 36px;
        @media(max-width: 899.95px){
            margin-bottom: 30px;
            img{
                max-height: 124px;
            }
        }
    }
}