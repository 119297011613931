@import "../../styles/custom/variables.scss";

.switches-container {
    width: 240px;
  display: flex;
  padding: 10px 16px;
  position: relative;
  background: #fff;
  border-radius: 7px;
  line-height: 24px;
  box-shadow: 0px 0px 7.3px 0px rgba(0, 0, 0, 0.04);
}

.switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
}

.switches-container label {
    width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: #6A737B;
  font-size: 16px;
  font-family: $font-weight-semibold;
  letter-spacing: -0.32px;
}

.switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 3;
    transition: transform .5s cubic-bezier(.77, 0, .175, 1);
    /* transition: transform 1s; */
}

.switch {
    border-radius: 7px;
    height: 100%;
}

.switch
    { 
    div {
        width: auto;
        text-align: center;
        opacity: 0;
        display: block;
        color: $black;
        transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
        will-change: opacity;
        position: absolute;
        top: 8px;
        left: 0;
        font-size: 16px;
        background-color: $white;
        border-radius: 7px;
        border: 0.5px solid #6C747D;
        box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.25);
        font-family: $font-weight-bold;
        padding: 1px 20px;
}}

.switches-container input:nth-of-type(1):checked~.switch-wrapper {
    transform: translateX(0%);
}

.switches-container input:nth-of-type(2):checked~.switch-wrapper {
    transform: translateX(100%);
}

.switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
    opacity: 1;
}

.switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
    opacity: 1;
}