@import "../../styles/custom/variables.scss";

.page-wrap{
    font-family: $baseFontFamily;
    .MuiContainer-root{
        @media(max-width: 899.95px){
            padding-left: 24px;
            padding-right: 24px;
        }
    }
    .title{
        font-family: $home-heading-family;
        font-weight: 700;
    }
    .subtitle{
        font-family: $home-body-family;
        font-weight: 400;
    }
    .text-sm-bold{
        font-weight: 600;
    }
    .text-xl{
        font-size: 65px;
        @media(max-width: 1100.95px){
            font-size: 41px;
        }
    }
    .text-h1{
        font-size: 48px;
        line-height: 61px;
        @media(max-width: 1100.95px){
            font-size: 30px;
            line-height: normal;
            text-align: center;
        }
    }
    .text-h2{
        font-size: 32px;
        line-height: 38px;
        @media(max-width: 1100.95px){
            font-size: 22px;
            line-height: normal;
        }
    }
    .text-h3{
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0px;
        @media(max-width: 1100.95px){
            font-size: 16px;
            line-height: 22px;
        }
    }
    .text-body2{
        font-size: 14px;
        line-height: 20px;
        @media(max-width: 1100.95px){
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0px;
        }
    }
    .text-muted{
        color: #4E4A4A;
    }
    .btn{
        min-width: 176px;
        font-size: 20px;
        letter-spacing: -0.4px;
        min-height: 52px;
        &.btn-primary{
            background-color: #FFC90C;
            border-color: #FFC90C;
            color: #000;
        }
        @media(max-width: 899.95px){
            width: 176px;
            font-size: 20px;
            letter-spacing: -0.243px;
            height: 52px;
            padding: 10px 13px;
            line-height: 20px;
            border-radius: 7px;
            letter-spacing: -2%;
        }
        &.with-icon{
            @media(max-width: 899.95px){
                gap: 10px;
                img{
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
    .mw-100{
        max-width: 100%;
    }
    .w-100{
        width: 100%;
    }
    @media(max-width: 390.95px){
        overflow-x: hidden;
    }
}