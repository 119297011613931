.main_div {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  height: 100vh;

}
.sections_container{
  display: flex;
  width: 100%;
}
.first_section {
  flex: 0 0 auto;
  transition: width 0.3s;
  /* height: 100vh; */
  /* background-color: green; */
  margin: 0;
  padding: 0;
  /* overflow: scroll; */

}
.first_section_small {
  width: 100%;
  height: 100vh;
  /* background-color: green; */
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.first_section::-webkit-scrollbar {
  width: 0em !important;
}

.first_section::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.first_section::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  width: 100%;
  position: sticky;
}
.heading_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 50px;

}
.heading_section p {
  font-family: "IBM Plex Serif", serif;
  font-weight: 400;
  font-size: 40px;
  margin-left: 53px;
}
.heading {
  font-family: "IBM Plex serif", serif;
  font-size: 36px;
  font-weight: 400;
  width: 282px;
  border: none;
  margin: 24px 0;
  padding-top: 15px;
}

.edit_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin:12px 30px 0px 0px;
  margin-right: 50px;
}
.highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px px 4px 6px;
  border-radius: 5px;
  border: 1px solid #cecece;
  background: var(--white, #fff);
  height: 27px;
  width: 27px;
  cursor: pointer;
}
.highlight img {
  width: 15px;
  height: 15px;
}
.text_edit {
  width: 35px;
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding: 3px 11px;
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid #cecece;
  background: var(--pdfbg, #fffcfc);
}
.small_A {
  font-size: 12px;
  font-family: "IBM Plex Sans", sans;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
}
.seperator {
  font-weight: 700;
  margin: 0;
}
.capital_A {
  font-size: 16px;
  font-family: "IBM Plex Sans", sans;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
}
.slider {
  position: absolute;
  top: 0;
  height: 100%;
  width: 6px;
  background-color: #0e0e0e;
  cursor: ew-resize;
}

.handle {
  position: absolute;
  top: 50%;
  left: -6px;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: #070707;
  border-radius: 50%;
  z-index: 9999;
}
.summary_btn{
  color: #FFF;
  width: 85px;
  margin-left: 10px;
  margin-top: 10px;
  padding: 3px 12px;
  border-radius: 5px;
  background: #1D1D1D;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
font-family: IBM Plex Sans;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
border: none;
cursor: pointer;
}
.summary_section {
  
  font-family: "IBM Plex Sans", sans;
  width: 79%;
  margin-left: 80px;
  margin-top: 0px;
  padding: 15px 13px 0px 18px;
  border-radius: 5px;
  background: rgba(189, 189, 189, 0.30);
  /* background-color: red; */
  position: relative;
  z-index: 2;
  margin-top: -11px;
}
.summary_heading {
font-family: IBM Plex Sans;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
text-transform: capitalize;
  margin: 0px;
  margin-bottom: 10px;
  
}
.summary {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin: -8px 0px;
  height: 120px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.summary::-webkit-scrollbar {
  width: 10x;
}

.summary::-webkit-scrollbar-track {
  background: rgba(46, 46, 46, 0.3);
}

.summary::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.summary::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.hide_parent{
  display: flex;
  justify-content: end;
  align-items: start;
}
.hide_parent button {
  width: 55px;
  height: 23px;
  padding: 3px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 12px;
  background: #1d1d1d;
  border: none;
  margin: 0;
  margin-bottom: 10px;
  cursor: pointer;
}
.pdf_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 20px; */
  height: 100vh;
  /* overflow: scroll; */
  /* background-color: red; */
  position: relative;
}
.pdf_section::-webkit-scrollbar {
  width: 0em !important;
}

.pdf_section::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.pdf_section::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}
.pdf_icons {
 position: absolute;
 bottom: 30%;
 left: 1%;
 z-index: 6;
}
.chalkboard_icon {
  /* height: 16px; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 20px;
  padding: 10px 6px;
  border: 1px solid #cecece;
  background-color: white;
  border-radius: 15px 15px 0px 0px;
  cursor: pointer;
}
.penswirl_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 6px;
  max-width: 20px;
  border: 1px solid #cecece;
  background-color: white;
  border-radius: 0px 0px 15px 15px;
  cursor: pointer;
}
.rectangle_icon {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 6px;
  max-width: 40px;
  min-height: 17px;
  border: 1px solid #cecece;
  background-color: white;
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
}
.drawer{
  margin-top: 10px;
  /* display: flex; */
  width: 250px;
  height: 145px;
  overflow: scroll;
  padding: 0px 10px;
  position: absolute;
 /* border: 1px solid  #E1E1E1;; */
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  left: 40px;
  bottom: -115px;
  z-index: -2;
}
.drawer::-webkit-scrollbar {
  width: 0em !important;
}

.drawer::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.drawer::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}
.tracker_text{
  color: #000;
font-family: IBM Plex Sans;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 33px; /* 220% */
text-transform: capitalize;
}
.tracker_title{
  color: #000 !important;
font-family: IBM Plex Sans !important;
font-size: 14px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 25px !important; /* 178.571% */
padding: 10px 0px 7px 0px;
border-bottom: 1px solid  #E1E1E1;
border-bottom-style: dashed;
}
.penswirl_icon p{
  margin: 0;
  font-family: IBM Plex Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 220% */
  text-transform: capitalize;
text-align: left;

}
.chalkboard_icon p{
  margin: 0;
  font-family: IBM Plex Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 220% */
  text-transform: capitalize;
text-align: left;

}
.rectangle_icon p{
  margin: 0;
  color: #000;
  text-align: center;
  font-family: Font Awesome 6 Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 33px; /* 220% */
  text-transform: capitalize;
text-align: left;

}
/* .rectangle_icon:hover{
  background:  #f6f6f6;
  color: #000;
  min-width: 90px;
  padding: 2px 6px;
  margin-top:10px;
  border:none
} */
.penswirl_icon:hover{
  background: #1D1D1D;
  color: white;
  min-width: 120px;
  padding: 2px 2px;
  margin: 0;

}
.chalkboard_icon:hover{
  background:  #1D1D1D;
  color: white;
  min-width: 95px;
  padding: 2px 2px;
  margin: 0;
}

.pdf_img {
  /* background-color: black; */
  width: 90%;
}
.pdf_img img {
  width: 100%;
  height: 370px;
  margin-left: 13px;
  object-fit: cover;
  object-position: 100% 0%;
  margin-top: 20px;
  /* background-color: red; */
}
.second_section {
  flex: 0 0 auto;
  transition: width 0.3s;
  height: 100vh;
  position: relative;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  /* background-color: red; */
}

.filters_box {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  width: 35px;
  cursor: pointer;
  /* height: 27px; */
  background-color: white;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  background: var(--pdfbg, #fffcfc);
  position: absolute;
  top: 15px;
  right: 10px;
}
.character_img img {
  position: absolute;
  bottom: -18px;
  right: 0px;
  width: 130px;
}
.second_section_text {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  font-family: "IBM Plex Sans", sans;
  font-size: 17px;
  line-height: 1.7;
  /* background-color: red; */
  margin-top: -180px;
  width: 75%;
}
.gif_container{
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: end;
  }
  .gif_container_gif{
    margin-right: 80px;
    margin-top: 40px;
    width: 23.5%;
  }
.loadingstate{
  display:flex;
   flex-direction:column;
    width:100%;
    height:100vh;
     justify-content:center;
     align-items:center
}
.loadingstate p {
  margin-top: 18px;
}
.fade_in_paragraph {
  width: 100%;
  opacity: 0;
  overflow: scroll;
  animation: fadeIn 4s ease-in-out forwards;
}
.fade_in_paragraph::-webkit-scrollbar {
  width: 0em !important;
}

.fade_in_paragraph::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.fade_in_paragraph::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}


.fade_in_line {
  opacity: 1;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.menu_item_custom {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Change this value as needed to control the width before truncation */
  font-family: IBM Plex Sans;
font-size: 14px !important;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 178.571% */
}
.inputAi{
  min-width: 98%; 
  color: #2f2f2f; font-size: 14px; font-family: IBM Plex Serif; font-weight: 400; line-height: 27px; word-wrap: break-word;
  z-index: 1;
  border: none;
 
}
.inputAi:hover {
  border:none; /* Replace this with the desired hover border color */
}

/* Change placeholder color on hover */
.custom-input:hover::placeholder {
  color: #292929; /* Replace this with the desired hover placeholder color */
}
.question{
  color: #6C6C6C;
font-family: IBM Plex Serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 17px; /* 192.857% */
margin-top: 20px;
}
.dropDown{
  color: #000;
font-family: IBM Plex Serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 27px; /* 192.857% */
  cursor: pointer;
}
.dropDownIcon{
 color: black; font-size: 14px; font-family: Font Awesome 6 Pro; font-weight: 400; line-height: 27px; word-wrap: break-word;
 margin-right: 10px;
 cursor: pointer;
}

.dropdownItem{
  background-color: rgba(0, 0, 0, 0.03);
  color: #000;
  font-family: IBM Plex Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
text-align: start;
padding: 10px 20px;
margin: 4px 0;
border-radius: 10px;
cursor: pointer;
}
.scrollView{
  z-index: 1;
  overflow: scroll;

}
.scrollView::-webkit-scrollbar {
  width: 0em !important;
}

.scrollView::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.scrollView::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}
.childDiv{
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.childDivTypist{
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-top: 70px;
}
.mermaidData{
  max-height: 300px;
  overflow: scroll;
  cursor: pointer;
}
.mermaidData::-webkit-scrollbar {
  width: 0em !important;
}

.mermaidData::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.mermaidData::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}
.dot{
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-bottom: 5px;
  border: 1px solid #DFDFDF;
}
.loading{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding:30px;
}
.story_gif{
  width: 50%;
}
.nodata{
  display: flex;
  flex-direction: column;
  align-items:start;
  justify-content: space-between;
  height: 100%;
}
.nodata p{
  color: #000;

/* AI Response */
font-family: IBM Plex Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 168.75% */
margin: 10px;
}
.nodata_text{
     margin-top: 110px;
     margin-left: 20px;
     font-size: 17px;
     font-weight: 400;
     line-height: 20px;
    
}
.typing{
  color: #000;

  /* AI Response */
  font-family: IBM Plex Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 168.75% */
}
@media (max-width: 768px) {
  .heading_section{
    margin-left: 5px;
  }
  .edit_section{
    margin-right: 10px;
  }
  .slider{
    display: none;
  }
  .first_section_small::-webkit-scrollbar {
    width: 0em !important;
  }
  
  .first_section_small::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
  
  .first_section_small::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }

}
