.main_div {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%));
}
 button {
  margin-top: 35px;
  margin-bottom: -7px;
  display: inline-flex;
  padding: 11px 114px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: black;
  color: white;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  border: none;
}
.second_section {
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  position: relative;
}
.second_section_text {
  /* background-color: red; */
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.8;
}
.second_section_text p{
  color: #000;
margin: 0;
/* AI Response */
font-family: IBM Plex Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 168.75% */
}
.story_gif{
  width: 40%;
}
.second_child {
  text-align: start;
  display: flex;
flex-direction: column;
align-items: start;
justify-content: start;
position: relative;
}
.second_child p{
  color: #000;

/* AI Response */
font-family: IBM Plex Sans;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 6px; /* 168.75% */
}
@media (max-width: 768px) {
  .main_div {
    height: 100vh;
    width: 100%;
  }
  .second_section {
    padding: 20px;
    height: 100%;
    display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  }
  .second_child {
    width: 100%;
    text-align: start;
    display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: relative;
  }
  .story_gif{
    width: 50%;
  }
  .btn_parent{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
