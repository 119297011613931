@import "../../styles/custom/variables.scss";

.circle-loader{
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
}
.quiz-settings-area{
    .item{
        margin-bottom: 24px;
        &:last-child{
            margin-bottom: 0;
        }
        .checkboxes-wrap {
            display: flex;
            flex-wrap: wrap;
            label {
                margin-top: 20px;
                flex: 0 0 50%;
                max-width: 50%;
                color: $black;
              }
          }
    }
    .item-flex{
        display: flex;
        align-items: center;
        .label-area {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            .text-small{
                color: #8A939F;
            }
          }
        .count-item {
            flex-shrink: 0;
            display: flex;
            align-items: stretch;
            border: 2px solid #D1D6DB;
            border-radius: 8px;
            overflow: hidden;
            .btn {
                padding: 3px 12px;
                font-size: 24px;
                line-height: normal;
                border-color: transparent;
                border-radius: 0;
              }
              input {
                max-width: 52px;
                text-align: center;
                border-left: 2px solid #D1D6DB;
                border-right: 2px solid #D1D6DB;
                border-top: none;
                border-bottom: none;
                font-size: 14px;
                color: #877575;
                letter-spacing: -0.28px;
              }
        }
    }
}