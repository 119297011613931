@import "../../styles/custom/variables.scss";

.tabs-wrap{
  .MuiTabs-indicator {
    display: none;
  }
  .tab-btn {
    font-family: "Metropolis Medium";
    .MuiTouchRipple-root{
      display: none;
    }
  }
  .MuiTabPanel-root{
    padding: 0;
  }
  &.chat-area-tabs{
    height: 100%;
    display: flex;
    flex-direction: column;
    .MuiTabs-root{
      min-height: auto;
    }
  .MuiTabs-scroller {
      position: absolute;
      right: 0;
      left: auto;
      white-space: normal;
      width: 42px;
      background: linear-gradient(180deg, #ECEEEF 0%, rgba(243, 243, 243, 0) 125.5%);
      min-height: 302px;
      display: flex;
      top: 0
    }
    .MuiTabs-flexContainer {
      flex-direction: column;
      padding-top: 33px;
    }
    .tab-label-wrap {
      transform: rotate(90deg);
    }
    .tab-btn {
      min-width: 24px;
      text-transform: none;
      font-family: "Metropolis Medium";
      line-height: normal;
      padding: 10px 0;
      display: flex;
      min-height: 75px;
      max-width: 24px;
      background-color: #E7E7E7;
      height: auto;
      max-height: 120px;
      flex-grow: 1;
      max-height: 134px;
      white-space: nowrap;
      color: #677180;
      font-size: 16px;
      border-top-right-radius: 7px;
      &:first-of-type{
          max-height: 75px;
      }
      &:not(:first-of-type){
          border-bottom-right-radius:7px;
          border-top-right-radius: 0;
      }
      &.Mui-selected{
          background-color: #fff;
          color: #020202;
          font-family: $font-weight-semibold;
          max-width: 34px;
          border-top-right-radius: 7px;
          border-bottom-right-radius: 7px;
          border-top: 1px solid #B8C6D2; 
          border-right: 1px solid #B8C6D2; 
          border-bottom: 1px solid #B8C6D2;
          box-shadow: 3px 3px 5.8px 0px rgba(0, 0, 0, 0.08);  
      }
    }
    .tab-content-wrap{
      flex-grow: 1;
      .MuiTabPanel-root{
        padding: 0;
        height: 100%;
      }
    }
  }
  .MuiTabs-vertical{
    .tab-btn{
      padding: 0;
      min-height: auto;
      align-items: flex-start;
      justify-content: flex-start;
      border-right: 1px solid #DEE7F0;
      border-bottom: 1px solid transparent;
      font-size: 14px;
      text-transform: none;
      color: #707982;
      line-height: 14px;
      min-width: 193px;
      position: relative;
      // transition: all 0.3s ease-in-out 0s;
      text-align: left;
      &::before {
        display: block;
        content: "";
        background-color: #000;
        width: 4px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        // transition: all 0.3s ease-in-out 0s;
        opacity: 0;
        margin-top: -1px;
      }
      &.Mui-selected{
        color: $black;
        font-size: 16px;
        line-height: 16px;
        border-right-color: transparent;
        border-bottom-color: #DEE7F0;
        &::before {
          opacity: 1;
        }
        .icon{
          img{
            filter: invert(0) sepia(0%) saturate(22%) hue-rotate(329deg) brightness(0%) contrast(101%);
          }
        }
        .tab-label-wrap{
          padding: 15px 10px 15px 15px;
          border-bottom-color: transparent;
        }
      }
      .tab-label-wrap{
        display: flex;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid #DEE7F0;
        padding: 15px 20px 15px 0px;
        // transition: all 0.3s ease-in-out 0s;
        .tab-label{
          flex-grow: 1;
        }
        .icon{
          flex-shrink: 0;
        }
      }
    }
  }
}