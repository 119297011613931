@import "../../styles/custom/variables.scss";

.tooltiphover-wrap{
    .MuiTooltip-tooltip{
        white-space: normal;
        background-color: $white;
        color: #58636D;
        border-radius: 8px;
        border: 0.8px solid #5E636A;
        box-shadow: 0px 2px 11.9px 0px rgba(0, 0, 0, 0.34);
        padding: 8px 10px;
        max-width: fit-content;
        min-width:auto;
    }
    .MuiTooltip-arrow{
        color: #58636D;
    }
}
