
.scrollable{
    scrollbar-color: #DFE1E2 transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar{
        height: 8px;
        width: 8px;
    }
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-corner{
        background-color: transparent;
        cursor: pointer;
    }
    &::-webkit-scrollbar-thumb{
        background-color:#fff;
        border-radius: 8px;
        border: solid 2px transparent;
        background-clip: content-box;
        min-height: 60px;
        &:hover{
            background-color: #fff;
        }
    }
    &::-webkit-scrollbar-track{
        background-color: #DFE1E2;
        border-radius: 10px;
    }
    &.overlayScroll{
        overflow-y: overlay;
        @-moz-document url-prefix() {
            overflow-y: auto;
        }
    }
}