.App {
  text-align: center;
  background-color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Hide the scroll bar */
body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  margin: 0;
  padding: 0;
}

/* Hide the scroll bar for WebKit-based browsers */
body::-webkit-scrollbar {
  width: 0em;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.App{
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; 
}
.PdfHighlighter{
  overflow: scroll !important;
}
.PdfHighlighter::-webkit-scrollbar {
  width: 0em !important;
}

.PdfHighlighter::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.PdfHighlighter::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
border: none !important;
}
.pdf-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.pdf-page {
  width: auto; /* Let the page's natural width determine its size */
  max-width: 100%; /* Limit page width to the viewport width */
  flex-grow: 1;
  overflow: hidden;
}

.pdf-highlighter {
  position: relative;
  width: 100%;
  height: 100%;
}

.Highlight--scrolledTo .Highlight__part{
  cursor: auto !important;
  background-color: transparent !important;
  background: transparent !important;
}
.Highlight__part{
  background: transparent !important;
  color: #61dafb !important;
  cursor: auto !important;
}
input:focus-visible {
  outline: none; /* Remove the default outline */
  border-color: transparent; /* Set the border color to transparent */
  /* Add any additional styling you want for the focused state */
}
@media (max-width: 768px) {
  .css-6s1xft-MuiGrid-root>.MuiGrid-item{
    padding: 0 !important;
  }
  .css-6s1xft-MuiGrid-root{
    width: 100% !important;
    margin-left: 0px !important;
   
  }
  .css-6sap9-MuiGrid-root>.MuiGrid-item{
    padding-top: 0 !important;
  }
  .css-1gkl6l1-MuiGrid-root{
    max-width: 99% !important;
    padding: 0 !important;
  }
  .css-1kfhsve-MuiGrid-root{
    max-width: 99% !important;
    padding: 0 !important;
  }
  .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
    background-color: transparent !important;
  }
  .css-c18xrk-MuiModal-root-MuiDrawer-root{
    background: transparent !important;
  }
  .css-9emuhu-MuiPaper-root-MuiDrawer-paper{
    height: 100vh !important;
  }
  .css-1e4sykn{
    padding: 0 !important;
  }
  
}
* {
  user-select: text !important;
}

.textLayer{

  width: 100%;
  height: 100%;
  user-select: text !important; /* Allow only text selection */
}
.textLayer span, .textLayer br{
  user-select: text !important; /* Allow only text selection */
}