@import "../../styles/custom/variables.scss";

.home-intro-wrap{
    padding-top: 60px;
    .sec-content{
        .subtitle{
            margin-top: 38px;
        }
        .btn{
            margin-top: 40px;
        }
    }
    .sec-img{
        img{
            @media(max-width: 899.95px){
                max-height: 337px;
            }
        }
    }
    .btn{
        @media(max-width: 899.95px){
            margin: auto;
        }
    }
    .MuiGrid-item{
        &:first-child{
            @media(max-width: 899.95px){
                order: 2;
            }
        }
        &:last-child{
            @media(max-width: 899.95px){
                order: 1;
            }
        }
    }
    .order-reverse{
        .MuiGrid-item{
            &:first-child{
                order: 2;
            }
            &:last-child{
                order: 1;
            }
        }
    }
    @media(max-width: 899.95px){
        text-align: center;
    }
}