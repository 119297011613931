@import "../fonts.scss";

$black: #000000;
$grey: #2F3538;
$textGreyLight: #656A71;
$white: #ffffff;
$bodyBg: #F3F3F3;
$textMuted: #484E55;
$borderColor: #707A83;
$danger: #FF7355;
$success: #45BB1D;
$blueBayoux: #566E8A;

$baseFontFamily: 'Metropolis Medium';
$font-weight-normal: 'Metropolis Regular';
$font-weight-medium: 'Metropolis Medium';
$font-weight-semibold: 'Metropolis Semi Bold';
$font-weight-bold: 'Metropolis Bold';

$home-heading-family: "Outfit", sans-serif;
$fw-bold: 700;
$home-body-family: "Fredoka", sans-serif;
