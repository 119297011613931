@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Happy+Monkey&family=Outfit:wght@100..900&display=swap');
@font-face {
    font-family: 'Metropolis Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/Metropolis-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Metropolis Medium';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/Metropolis-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Metropolis Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/Metropolis-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Metropolis Bold';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/Metropolis-Bold.woff') format('woff');
}