@import "../../styles/custom/variables.scss";

.radio-btn {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    margin-bottom: 5px;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 11px;
        width: 11px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid $black;
    }
    &:hover {
        input ~ .checkmark {
            background-color: $black;
        }
    }
    input:checked ~ .checkmark {
        background-color: $black;
    } 
  }