@import "../../styles/custom/variables.scss";

.dialog-text-magic {
    .dialog-title {
        .text-h2{
            font-size: 26px;
        }
    }
    .MuiDialog-paper{
        top: 80px;
        overflow: visible;
        position: relative;
        &::before {
            display: block;
            content: "";
            width: 0px;
            height: 0;
            border-style: solid;
            border-color: transparent $white transparent transparent;
            border-width: 14px 23px;
            position: absolute;
            left: -45px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.mermaid{
    text-align: center;
}