@import "../../styles/custom/variables.scss";

.tooltip-wrap{
    .MuiTooltip-tooltip{
        white-space: normal;
        background-color: $white;
        color: #58636D;
        border-radius: 8px;
        border: 0.8px solid #5E636A;
        box-shadow: 0px 2px 11.9px 0px rgba(0, 0, 0, 0.34);
        padding: 12px 16px 12px;
        min-width: 375px;
    }
    .MuiTooltip-arrow{
        color: #58636D;
    }
    .soc-learning-small {
        font-family: $font-weight-normal;
        font-size: 12px;
        line-height: 13.5px;
        letter-spacing: -0.02rem;
        color:#A9B3BD;        ;
    }
}
