@import "../../styles/custom/variables.scss";

.home-confirmation-dialog{
    .MuiPaper-root{
        background-color: #3F3B27;
        color: #fff;
        font-family: "Happy Monkey", system-ui;
        box-shadow: 0px 6px 33px 11px rgba(0, 0, 0, 0.27);
    }
    .dialog-wrap{
        .dialog-header{
            border-bottom: none;
            position: relative;
            .text-h2{
                font-size: 32px;
                letter-spacing: 0.32px;
                font-weight: 400;
                font-family: "Happy Monkey", system-ui;
            }
            &::before{
                display: block;
                content: "";
                background-color: rgba(255, 255, 255, 0.53);
                width: calc(100% - 80px);
                height: 1px;
                position: absolute;
                bottom: 0;
            }
        }
        .dialog-content{
            max-width: 226px;
            margin: auto;
            .text-h4{
                letter-spacing: normal;
                line-height: 24px;
                font-weight: 400;
                font-style: normal;
            }
        }
        .dialog-action{
            .btn{
                min-width: 123px;
                min-height: 36px;
                font-size: 16px;
                letter-spacing: normal;
                padding: 10px;
                border-radius: 7px;
                font-family: "Happy Monkey", system-ui;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}